import clsx from 'clsx';
import ErrorIcon from '@/icons/exclamation.svg';
import SuccessIcon from '@/icons/success.svg';

interface HelpTextProps extends React.ComponentProps<'div'> {
  type: 'error' | 'info' | 'success' | 'warning';
}

export default function HelpText({
  type,
  className,
  children,
  ...props
}: HelpTextProps) {
  return (
    <div
      className={clsx('help-text', `help-text--${type}`, className)}
      {...props}
    >
      <div className="help-text__icon">
        {type === 'success' && <SuccessIcon />}
        {(type === 'error' || type === 'warning') && <ErrorIcon />}
      </div>
      <p className="help-text__message">{children}</p>
    </div>
  );
}
