import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { signIn } from 'next-auth/react';
import { z } from 'zod';
import type { GetServerSidePropsContext } from 'next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { trpc } from '@/lib/trpc';
import { ERROR_CODES } from '@/lib/auth';
import { getServerSession } from '@/server/utils/auth';

import Button from '@/components/Button';
import Logo from '@/icons/tiviti-logo.svg';
import Background from '@/icons/background.svg';
import InputGroup from '@/components/InputGroup';
import HelpText from '@/components/HelpText';
import SignUpModal from '@/components/auth/SignUpModal';
import Digits from '@/components/auth/Digits';

const schema = z.object({
  email: z
    .string()
    .min(1, 'An email address is required')
    .email('A valid email address is required'),
  password: z.string().min(12, 'A password is required'),
  code: z.string().optional(),
});

const errorMessages = {
  login: (
    <>
      Something went wrong. Did you{' '}
      <Link href="/forgot-password" passHref>
        forget your password
      </Link>
      ?
    </>
  ),
  two_factor: <>Invalid 2FA code. Please generate a new code and try again.</>,
};

type LoginData = z.infer<typeof schema>;

export default function LoginPage() {
  const router = useRouter();
  const [modalOpen, setModalOpen] = useState(false);
  const [showCodeInput, setShowCodeInput] = useState(false);
  const [errorMessage, setErrorMessage] = useState<React.ReactNode | null>(
    null
  );
  const twoFactorMutation = trpc.auth.setupTwoFactorAuth.useMutation();

  const { register, formState, handleSubmit, ...methods } = useForm<LoginData>({
    resolver: zodResolver(schema),
  });

  const onSubmit = async (values: LoginData) => {
    setErrorMessage(null);

    const res = await signIn('credentials', {
      email: values.email,
      password: values.password,
      totpCode: values.code ?? '',
      redirect: false,
    });

    // Call GET /api/2fa to get to get 2FA status for user
    // If not set up, show modal. If it is, redirect to dashboard
    const shouldEnable2FA = (await fetch('/api/auth/2fa').then((response) =>
      response.json()
    )) as { shouldEnable: boolean };

    if (res?.error) {
      if (
        res.error === ERROR_CODES.twoFactorCodeRequired ||
        res.error === ERROR_CODES.twoFactorInvalid
      ) {
        setShowCodeInput(true);

        if (res.error === ERROR_CODES.twoFactorInvalid) {
          setErrorMessage(errorMessages.two_factor);
        }
      } else {
        setErrorMessage(errorMessages.login);
      }
    }

    // If the result was okay, check if we need to set up 2FA
    if (res?.ok && shouldEnable2FA.shouldEnable) {
      setErrorMessage(null);
      twoFactorMutation.mutate(undefined, {
        onSuccess: () => {
          setModalOpen(true);
        },
        onError: () => {
          setErrorMessage(errorMessages.two_factor);
        },
      });
    }

    // If the result was okay and we don't need to set up 2FA, proceed to the dashboard
    if (res?.ok && !shouldEnable2FA.shouldEnable) {
      router.push('/');
    }
  };

  return (
    <>
      <Head>
        <title>Tiviti: Login</title>
      </Head>
      <div className="login">
        <div className="row-0 login__row">
          <div className="col-6 login__col login__col--left">
            <div className="login__inner">
              <figure className="login__figure">
                <Logo className="login__logo" />
              </figure>

              <h1 className="font-size-70 login__heading">Welcome back</h1>

              <p className="font-size-30 login__content">
                {showCodeInput
                  ? 'Enter the sign-in 2FA code from your authenticator app.'
                  : 'To continue, log into Tiviti'}
              </p>

              {errorMessage && (
                <div className="login__error-message">
                  <HelpText type="warning">{errorMessage}</HelpText>
                </div>
              )}

              <FormProvider
                register={register}
                formState={formState}
                handleSubmit={handleSubmit}
                {...methods}
              >
                <form
                  className="form login__form"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  {!showCodeInput ? (
                    <>
                      <InputGroup
                        className="login__input"
                        disabled={
                          !!(formState?.isLoading || formState?.isSubmitting)
                        }
                        errorMessage={formState?.errors?.email?.message ?? ''}
                        fieldSize="large"
                        label="Email address"
                        type="email"
                        wrapperClassName="login__form-item"
                        {...register('email')}
                      />

                      <InputGroup
                        className="login__input"
                        disabled={
                          !!(formState?.isLoading || formState?.isSubmitting)
                        }
                        errorMessage={
                          formState?.errors?.password?.message ?? ''
                        }
                        fieldSize="large"
                        label="Password"
                        link="/forgot-password"
                        linkLabel="Forgotten password?"
                        type="password"
                        wrapperClassName="login__form-item"
                        {...register('password')}
                      />
                    </>
                  ) : (
                    <Digits className="sign-up__digits" showMessage />
                  )}

                  <Button
                    className="login__submit"
                    disabled={
                      !!(formState?.isLoading || formState?.isSubmitting)
                    }
                    isLoading={
                      !!(formState?.isLoading || formState?.isSubmitting)
                    }
                    type="submit"
                    variant="primary"
                  >
                    Sign in
                  </Button>
                </form>
              </FormProvider>
            </div>
            <SignUpModal
              qrCode={twoFactorMutation?.data?.qrCode}
              open={modalOpen}
              onOpenChange={setModalOpen}
              cancelText="Skip"
            />
          </div>

          <div className="col-6 login__col login__col--right">
            <Background className="login__background" />
          </div>
        </div>
      </div>
    </>
  );
}

export async function getServerSideProps(ctx: GetServerSidePropsContext) {
  const session = await getServerSession(ctx.req, ctx.res);

  if (session?.user) {
    return {
      redirect: {
        destination: '/',
        permanent: false,
      },
    };
  }

  return {
    props: {},
  };
}
