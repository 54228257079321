export const ERROR_CODES = {
  internalServerError: 'INTERNAL_SERVER_ERROR',
  userNotFound: 'USER_NOT_FOUND',
  incorrectUsernamePassword: 'INCORRECT_USERNAME_OR_PASSWORD',
  newPasswordMatchesOld: 'NEW_PASSWORD_MATCHES_OLD',
  twoFactorCodeRequired: '2FA_CODE_REQUIRED',
  twoFactorNotSetup: 'REQUIRED_2FA_SETUP',
  twoFactorAlreadyEnabled: '2FA_ALREADY_ENABLED',
  twoFactorInvalid: 'INVALID_2FA_CODE',
  twoFactorRecoveryKeyIncorrect: '2FA_RECOVERY_KEY_INCORRECT',
};

export enum UserRole {
  TIVITI_ADMIN = 3,
  TIVITI_DELIVERY = 4,
  TIVITI_FIELD_ENGINEER = 5,
  TIVITI_SUPPORT = 6,
  TIVITI_USER = 7,
  ORG_ADMIN = 8,
  ORG_SUPPORT = 9,
  ORG_TECHNICAL_CONTACT = 10,
  ORG_USER = 11,
}

export const ROLE_NAME: Map<UserRole, string> = new Map([
  [UserRole.TIVITI_ADMIN, 'Tiviti Admin'],
  [UserRole.TIVITI_DELIVERY, 'Tiviti Delivery'],
  [UserRole.TIVITI_FIELD_ENGINEER, 'Tiviti Field Engineer'],
  [UserRole.TIVITI_SUPPORT, 'Tiviti Support'],
  [UserRole.TIVITI_USER, 'Tiviti Read-only User'],
  [UserRole.ORG_ADMIN, 'Organisation Admin'],
  [UserRole.ORG_SUPPORT, 'Organisation Support'],
  [UserRole.ORG_TECHNICAL_CONTACT, 'Organisation Technical Contact'],
  [UserRole.ORG_USER, 'Organisation Read-only User'],
]);

export const ROLE_LIST = {
  ALL_ROLES: [
    UserRole.TIVITI_ADMIN,
    UserRole.TIVITI_DELIVERY,
    UserRole.TIVITI_FIELD_ENGINEER,
    UserRole.TIVITI_SUPPORT,
    UserRole.TIVITI_USER,
    UserRole.ORG_ADMIN,
    UserRole.ORG_SUPPORT,
    UserRole.ORG_TECHNICAL_CONTACT,
    UserRole.ORG_USER,
  ],
  TIVITI_ROLES: [
    UserRole.TIVITI_ADMIN,
    UserRole.TIVITI_DELIVERY,
    UserRole.TIVITI_FIELD_ENGINEER,
    UserRole.TIVITI_SUPPORT,
    UserRole.TIVITI_USER,
  ],
  ORG_ROLES: [
    UserRole.ORG_ADMIN,
    UserRole.ORG_SUPPORT,
    UserRole.ORG_TECHNICAL_CONTACT,
    UserRole.ORG_USER,
  ],
  TECHNICAL_CONTACT_ROLES: [UserRole.ORG_TECHNICAL_CONTACT],
};
